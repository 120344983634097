export const APP_CONFIG ={
  "appName": "GNOde",
  "rootCatalog": "https://dev.haystac.gno-sys.com",
  "mobileSize": 600,
  "headerHeight": 56,
  "cognitoUserPoolId": "us-west-2_zLYqH9hgw",
  "cognitoUserPoolClientId": "1aa258abu13d50trqkionq0jmv",
  "cognitoIdentityPoolId": "us-west-2:3c859707-5a07-4b89-b6db-8672795e2b91",
  "analysysHubUrl": "https://analysis.gno-sys.com",
  "helpUrl": "https://help.gno-sys.com",
  "workflowStartUrl": "https://s5v7kgqnp0.execute-api.us-west-2.amazonaws.com/alpha",
  "workflowDescribeUrl": "https://8kc0dngdu4.execute-api.us-west-2.amazonaws.com/alpha",
  "workflowListUrl": "https://xzapf37x9f.execute-api.us-west-2.amazonaws.com/alpha",
  "workflowStopUrl": "https://qao5wr2bh5.execute-api.us-west-2.amazonaws.com/alpha",
  "workflows": [
    {
      "id": "lidar-processing-demo",
      "name": "Lidar Processing",
      "description": "Classify LiDAR data and create a DSM and DTM",
      "image": "https://stac-demo-public.s3.us-west-2.amazonaws.com/nm_test/LidarProcessing.png",
      "stateMachineArn": "arn:aws:states:us-west-2:365782598240:stateMachine:LidarProcessingDemo2",
      "s3bucket": "stac-demo-public",
      "s3key": "workflows/lidar-processing-demo",
      "s3region": "us-west-2",
      "params": [
        {
          "id": "collection_name",
          "name": "Collection Name",
          "type": "text",
          "required": true
        },
        {
          "id": "collection_description",
          "name": "Collection Description",
          "type": "multitext",
          "required": true
        },
        {
          "id": "collection_image",
          "name": "Collection Image",
          "type": "file",
          "filetypes": ".png,.jpg,.jpeg",
          "useHttpPath": true,
          "required": true
        },
        {
          "id": "las_file",
          "name": "LAS (or LAZ) File",
          "type": "file",
          "filetypes" : ".las,.laz,.copc",
          "required": true
        },
        {
          "id": "control_points_file",
          "name": "Control Points File (CSV)",
          "type": "file",
          "filetypes" : ".csv",
          "required": true
        },
        {
          "id": "other_files",
          "name": "Other File(s)",
          "type": "multifile",
          "filetypes": "*",
          "useHttpPath": true,
          "required": false
        },
      ]
    }
  ]
}
